<template>
  <CommonButton
    v-if="props.canBuyCost"
    type="primary"
    class="modal-btn flex flex-col items-center justify-center buy-btn"
    :disabled="!props.buyEnable"
    @click="onBuy"
  >
    <!--          <div v-if="buyType === 'gem'" class="flex items-center pb-0.5">-->
    <!--            <img src="~/assets/images/common/common_icon_dia.png" class="w-4 h-4">-->
    <!--            {{ props.shopItem.gemPrice.toLocaleString() }}-->
    <!--          </div>-->
    <!--          <div v-else-if="buyType === 'gold'" class="flex items-center pb-0.5">-->
    <!--            <img src="~/assets/images/common/common_icon_coin.png" class="w-4 h-4">-->
    <!--            {{ props.shopItem.goldPrice.toLocaleString() }}-->
    <!--          </div>-->
    <div>
      購入する
    </div>
  </CommonButton>
  <CommonButton v-else type="primary" class="modal-btn flex flex-col items-center justify-center buy-btn" @click="onGemAdd">
    <div v-if="isAnimatePlatform()">
      ゲソコイン<br>
      購入へ
    </div>
    <div v-else>
      ダイヤ<br>
      購入へ
    </div>
  </CommonButton>
</template>

<script lang="ts" setup>
import { isAnimatePlatform } from '~/libs/platform';

interface Props {
  buyEnable?: boolean,
  canBuyCost?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  buyEnable: true,
  canBuyCost: true,
});

interface Emits {
  (e: 'buy'): void;
  (e: 'cancel'): void;
}
const emit = defineEmits<Emits>();
/**
 * 有償通貨の購入
 */
const onGemAdd = async () => {
  emit('cancel');
  await navigateToPurchasePage();
};
/**
 * 購入処理
 */
const onBuy = () => {
  if (!props.buyEnable) {
    return;
  }
  emit('buy');
};
</script>
